<template>
  <div>
    <!-- 导航栏-->
    <Nav nav="news"></Nav>
    <!--    菜单-->
    <sideMenu></sideMenu>
    <!--  内容-->
    <div class="container-box">
      <!--    banner-->
      <div class="banner-box">
        <img src="https://file.xssbdc.com/static/website/banner7.png" alt="">
        <div>
          <span class="banner-text-title">新闻资讯</span>
          <span class="banner-text-title-en">结合设计经验与营销实践，提供有价值的互联网资讯</span>
        </div>

      </div>
      <div class="detail">
        <div class="detail-title text-center">
          <div class="container">
            <h1>{{ detail.title }}</h1>
            <p><span>发布日期：{{ detail.createTime }}</span></p>
          </div>
        </div>
        <div class="detail-content">
          <div class="container">
            <div v-html="detail.content"></div>
          </div>
        </div>

      </div>
    </div>
    <!--      脚标-->
    <tfooter></tfooter>
  </div>
</template>

<script>
import Nav from '../components/nav';
import sideMenu from '../components/side-menu';
import tfooter from "@/components/footer";
import url from '../js/config';

export default {
  name: "new-details",
  components: {Nav, sideMenu, tfooter},
  data() {
    return {
      id:'',
      detail:{}
    }
  },
  mounted() {
  this.id=this.$route.query.id;
  if(this.id){
    this.getDetail();
  }

  },
  methods:{
    getDetail(){
        this.$axios.get(url+`/business/websiteNews/getNews/${this.id}`).then(res=>{
            this.detail=res.data.data
        })
    },
  }

}
</script>

<style scoped lang="less">
.banner-box {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner-text-title {
      font-size: 76px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 66px;
    }

    .banner-text-title-en {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

}

</style>